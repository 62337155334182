import React from "react"
import {Helmet} from "react-helmet"
/* 
  The standard page layout template
*/

export function Layout(props){
  return (
    <main className={ (props.hicontrast) ? "min-h-screen bg-white text-black" :"min-h-screen bg-darkest-gray text-white flex flex-col justify-between"}>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>

      <div className="h-full pb-10">
        {props.children}
      </div>

      <footer className="flex flex-col justify-end px-10 py-5">
        © Austin Loza 2021
      </footer>
    </main>
  )
}


